import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useState } from 'react';
import { rem } from '../app/AppUtilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { useTraductions } from '../app/TraductionsContext';
import moment from 'moment';
import { useSettings } from '../app/SettingsContext';

interface Props {
  readyForPickupNotifiedAt: string | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.success.light,
      fontSize: rem(theme.generalStyles.smallerTextSize),
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: rem(20),
    },
    icon: {
      marginRight: rem(10),
      fontSize: rem(20),
      color: theme.palette.success.main,
    },
    text: {
      flex: 1,
      margin: `0 ${rem(10)} 0 0`,
      color: theme.palette.success.dark,
    },
    closeButton: {
      marginLeft: 'auto',
      alignSelf: 'flex-start',
      fontSize: rem(14),
      color: theme.palette.success.dark,
      border: 'none',
      background: 'transparent',
    },
  })
);

export function ReadyForPickupInfoBox({ readyForPickupNotifiedAt }: Props) {
  const [isVisible, setVisible] = useState(true);
  const classes = useStyles();
  const traductions = useTraductions();
  const settings = useSettings();

  if (!isVisible || !readyForPickupNotifiedAt) {
    return null;
  }

  const formattedDate = moment(readyForPickupNotifiedAt)
    .tz(settings.currentBranch?.timezone ?? '')
    .format('LLL');

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className={classes.root} data-testid="ready-for-pickup-info-box">
      <FontAwesomeIcon icon={faCircleCheck} className={classes.icon} />
      <p className={classes.text} data-testid="ready-for-pickup-info-text">
        {traductions.orderDetailPage.readyForPickupInfoText} <strong>{formattedDate}</strong>
      </p>
      <button className={classes.closeButton} onClick={onClose}>
        <FontAwesomeIcon icon={faXmark} />
      </button>
    </div>
  );
}
