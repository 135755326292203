import { OrderChannelEnum } from '../pauseChannel/types/PauseTypes';
import { ServiceType } from '../types';

export interface Data {
  order: Order;
}

export interface Variables {
  id: number;
  branchId: number;
  restaurantId: number;
}

export enum Status {
  UNKNOWN = 'UNKNOWN',
  NOT_PAID = 'NOT_PAID',
  PAID = 'PAID',
  REJECTED = 'REJECTED',
  VALIDATED = 'VALIDATED',
  IMPORTED = 'IMPORTED',
  IN_DISPATCH = 'IN_DISPATCH',
  IN_KITCHEN = 'IN_KITCHEN',
  IN_DELIVERY = 'IN_DELIVERY',
  COMPLETED = 'COMPLETED',
  VOIDED = 'VOIDED',
  REFUNDED = 'REFUNDED',
  SENDING_TO_POS = 'SENDING_TO_POS',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  ORDERED = 'ORDERED',
}

export interface Order {
  id: number;
  shortNumber: number;
  pickupDate: string;
  readyDate: string;
  isPaid: boolean;
  isRefundable: boolean;
  sessionId: string;
  status: Status;
  items: Item[];
  channel: OrderChannelEnum;
  thirdPartyOrderExternalReference: string | null;
  thirdPartyDeliveryTracking: string | null;
  isThirdPartyDelivery: boolean;
  thirdPartyOrderType: ThirdPartyOrderType;
  readyForPickupNotifiedAt: string | null;
  supportsReadyForPickup: boolean;
  customer: {
    allergies: string | null;
    remarks: string | null;
    fullName: string;
    phoneNumber: string;
    deliveryAddress: {
      apartment: string;
      civicNumber: string;
      streetName: string;
      city: string;
      province: string;
      country: string;
      postalCode: string;
    } | null;
  };
  serviceQuestions: ServiceQuestion[];
  service: {
    type: ServiceType;
    name: string;
    tableNumber: number | null;
    roomNumber: number | null;
    buzzerNumber: number | null;
    officeLocation: string | null;
    [key: string]: string | number | null;
  };
  fees: Fees;
  promotions: Promotion[] | null;
  taxes: Tax[];
  totals: {
    subTotal: number;
    total: number;
    tip: number;
  };
}

export interface Fees {
  allergyFees: number;
  applicationFees: number;
  deliveryFees: number;
  orderBagFees: OrderBagFee[];
}

export enum FeeType {
  BAG_FEE = 'BAG_FEE',
}

export enum BagType {
  BRING_YOUR_OWN_BAG = 'BRING_YOUR_OWN_BAG',
  NON_REUSABLE_BAG = 'NON_REUSABLE_BAG',
  REUSABLE_BAG = 'REUSABLE_BAG',
}

export interface OrderBagFee {
  totalChargedAmount: number;
  appliedQuantity: number;
  type: FeeType.BAG_FEE;
  bagType: BagType;
}

export interface Item {
  id: number;
  quantity: number;
  name: string;
  regularUnitPrice: number;
  note: string | null;
  extras: OptionAndExtra[] | null;
  options: OptionAndExtra[] | null;
}

export interface Tax {
  name: string;
  amount: number;
}

export interface OptionAndExtra {
  id: number;
  quantity: number;
  name: string;
  regularUnitPrice: number;
}

export interface Promotion {
  promotionalCode: string | null;
  amount: number;
}

export interface ServiceQuestion {
  level: number;
  prefixRange: string | null;
  suffixRange: string | null;
  rangeValue: string | null;
  text: string | null;
}

export const OrderNumberLabel = Object.freeze({
  DOOR_DASH: 'DoorDash',
  GOOGLE_FOOD_ORDERING: 'Order with Google',
  SKIP_THE_DISHES: 'SkipTheDishes',
  UBER_EATS: 'UberEats',
  KIOSK: 'Kiosk',
  UNKNOWN: 'UEAT',
  WEB: 'UEAT',
});

export enum ThirdPartyOrderType {
  UNKNOWN = 'UNKNOWN',
  DELIVERY = 'DELIVERY',
  TAKEOUT = 'TAKEOUT',
  THIRD_PARTY_DELIVERY = 'THIRD_PARTY_DELIVERY',
}
