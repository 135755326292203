import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Theme } from '@mui/material';
import { useTraductions } from '../app/TraductionsContext';
import { createStyles, makeStyles } from '@mui/styles';
import { rem } from '../app/AppUtilities';

interface Props {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      fontSize: rem(theme.generalStyles.mediumTextSize),
      padding: theme.generalStyles.modalButtonPadding,
      borderRadius: rem(10),
      textTransform: 'none',
    },
    textContent: {
      fontSize: rem(theme.generalStyles.mediumTextSize),
      fontWeight: 'bold',
      padding: theme.generalStyles.containerPadding,
    },
  })
);

export default function ReadyForPickupConfirmationModal({ isOpen, onConfirm, onCancel }: Props) {
  const traductions = useTraductions();
  const classes = useStyles();

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth={true}>
      <DialogContent>
        <DialogContentText
          classes={{
            root: classes.textContent,
          }}
          color="primary"
        >
          {traductions.orderDetailPage.readyForPickupConfirmation}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          classes={{
            root: classes.button,
          }}
          color="primary"
          variant="text"
          data-testid="ready-for-pickup-cancel-button"
        >
          {traductions.orderDetailPage.readyForPickupConfirmationNo}
        </Button>
        <Button
          onClick={onConfirm}
          classes={{
            root: classes.button,
          }}
          color="primary"
          variant="contained"
          data-testid="ready-for-pickup-confirm-button"
        >
          {traductions.orderDetailPage.readyForPickupConfirmationYes}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
