import { Box } from '@mui/material';
import React from 'react';
import { Order } from './OrderDetailTypes';
import { useTraductions } from '../app/TraductionsContext';
import SubTitle from './SubTitle';

interface Props {
  orderService: Order['service'];
}

const unsupportedKeys = ['name', 'type', '__typename'];

export default function ServicePrecisions({ orderService }: Props) {
  const traductions = useTraductions();

  const precisions = Object.entries(orderService).filter(([key, value]) => !unsupportedKeys.includes(key) && !!value);

  return (
    <>
      {precisions.map(([key, value]) => (
        <Box key={key}>
          <SubTitle>{traductions.orderDetailPage[key.replace('Number', '').replace('Location', '')]}</SubTitle>
          <p>{value}</p>
        </Box>
      ))}
    </>
  );
}
