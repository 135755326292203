import gql from 'graphql-tag';

export const RESTAURANTMANAGER_QUERY = gql`
  {
    myRestaurants {
      id
      headquarterId
      name
    }
  }
`;

export const RESTAURANT_BRANCHES_QUERY = gql`
  query branches($restaurantId: Int!) {
    branches(restaurantId: $restaurantId) {
      id
      name
      currency
      timezone
      hasManagerCode
      isPosIntegrated
      thirdPartyOrderIntegrations
      services {
        type
        name
        hasExternalDeliveryProvider
      }
    }
  }
`;

export const ORDERS_QUERY = gql`
  query orders($branchId: Int!, $restaurantId: Int!, $filter: OrderFilterEnum = null, $offset: Int!) {
    orders(branchId: $branchId, restaurantId: $restaurantId, limit: 20, offset: $offset, filter: $filter) {
      hasMore
      totalCount
      results {
        id
        shortNumber
        pickupDate
        isPaid
        status
        channel
        thirdPartyOrderExternalReference
        customer {
          fullName
          phoneNumber
        }
        service {
          type
          name
        }
        totals {
          total
        }
      }
    }
  }
`;

export const ORDER_DETAIL_QUERY = gql`
  query order($id: Int!, $branchId: Int!, $restaurantId: Int!) {
    order(id: $id, branchId: $branchId, restaurantId: $restaurantId) {
      id
      shortNumber
      pickupDate
      readyDate
      isPaid
      isRefundable
      sessionId
      status
      channel
      thirdPartyOrderExternalReference
      thirdPartyDeliveryTracking
      thirdPartyOrderType
      isThirdPartyDelivery
      readyForPickupNotifiedAt
      supportsReadyForPickup
      items {
        id
        name
        regularUnitPrice
        quantity
        note
        extras {
          id
          name
          quantity
          regularUnitPrice
        }
        options {
          id
          name
          regularUnitPrice
          quantity
        }
      }
      promotions {
        amount
        promotionalCode
      }
      customer {
        allergies
        remarks
        fullName
        phoneNumber
        deliveryAddress {
          apartment
          civicNumber
          streetName
          city
          province
          country
          postalCode
        }
      }
      service {
        type
        name
        tableNumber
        roomNumber
        buzzerNumber
        officeLocation
      }
      serviceQuestions {
        level
        text
        rangeValue
        prefixRange
        suffixRange
      }
      fees {
        allergyFees
        applicationFees
        deliveryFees
        orderBagFees {
          totalChargedAmount
          appliedQuantity
          type
          bagType
        }
      }
      taxes {
        name
        amount
      }
      totals {
        subTotal
        total
        tip
      }
    }
  }
`;

export const ORDER_POPUP_QUERY = gql`
  query order($branchId: Int!, $id: Int!, $restaurantId: Int!) {
    order(branchId: $branchId, id: $id, restaurantId: $restaurantId) {
      shortNumber
      pickupDate
      totals {
        total
      }
    }
  }
`;

export const RESTAURANT_DELAY_QUERY = gql`
  query prepTime($branchId: Int!) {
    preparationTime(branchId: $branchId) {
      base {
        permanentMinutes
        temporaryMinutes
        totalMinutes
        temporaryExpiration
      }
      delivery {
        permanentMinutes
        temporaryMinutes
        totalMinutes
        temporaryExpiration
      }
    }
  }
`;

export const MENU_ITEMS_QUERY = gql`
  query items($branchId: Int!, $offset: Int!, $limit: Int!, $search: String = null) {
    items(branchId: $branchId, offset: $offset, limit: $limit, search: $search) {
      totalCount
      hasMore
      results {
        id
        adminName
        state
        isStateReadOnly
      }
    }
  }
`;

export const USER_ROLE_QUERY = gql`
  query userRole {
    user {
      companyUserRole
      isGlobalAdmin
    }
  }
`;

export const THIRD_PARTY_DELIVERY_PARTNER_QUERY = gql`
  query thirdPartyDeliveryPartners($branchId: Int!) {
    thirdPartyDeliveryPartners(branchId: $branchId) {
      id
      order
      isDisabled
      thirdPartyDeliveryProviderName
    }
  }
`;

export const PAUSE_STATES_QUERY = gql`
  query pauseStates($branchId: Int!) {
    pauseStates(branchId: $branchId) {
      channel
      pausedUntil
      status
      statusCreatedAt
    }
  }
`;
