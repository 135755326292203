import { alpha, createTheme } from '@mui/material';
import { DefaultTheme } from '@mui/material/styles/createTheme';
import { Components } from '@mui/material/styles/components';
import { amber } from '@mui/material/colors';

import { rem } from './AppUtilities';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    bigContained: true;
    bigText: true;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface TypeText extends TypeTextOptions {}

  interface TypeTextOptions {
    default?: string;
  }
}

declare module '@mui/material/styles/createTheme' {
  export interface DefaultTheme extends Theme {}

  interface Theme extends DeprecatedThemeOptions {}

  interface ThemeOptions extends DeprecatedThemeOptions {}

  interface DeprecatedThemeOptions {
    generalStyles: {
      borderRadius: number;
      buttonOutline: number;
      modalButtonPadding: string;
      generalIconSize: number;
      smallerTextSize: number;
      mediumIconSize: number;
      bigIconSize: number;
      veryBigIconSize: number;
      baseTextSize: number;
      mediumTextSize: number;
      bigTextSize: number;
      biggerTextSize: number;
      largeTextSize: number;
      veryBigText: number;
      containerPadding: number;
      appBarPadding: number;
      appBarLargeScreenHeight: number;
      largeDecorationHeight: number;
      smallDecorationHeight: number;
      ordersBottomNavigationHeight: number;
      settingsInputContainerWidth: number;
      buttonHoverBoxShadow: string;
      buttonHoverFilter: string;
      lowDeviceHeight: number;
    };
    pauseComponent: {
      button: {
        background: {
          primary: string;
          secondary: string;
          tertiary: string;
        };
        border: {
          primary: string;
        };
        color: {
          pending: string;
        };
      };
      banner: {
        background: {
          default: string;
          paused: string;
        };
      };
      timer: {
        color: {
          default: string;
          pending: string;
        };
        background: string;
      };
    };
  }
}

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 601,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#000000',
      contrastText: 'white',
    },
    secondary: {
      main: '#393939',
      light: '#5B5858',
    },
    background: {
      default: '#F3F3F3',
    },
    text: {
      default: '#272727',
    },
    success: {
      main: '#00C08B',
      light: '#edfaf6',
      dark: '#007e5a',
    },
  },
  typography: {
    fontFamily: 'Nunito, Roboto, Arial, sans-serif',
  },
  spacing: 8,
  generalStyles: {
    borderRadius: 5,
    buttonOutline: 2,
    modalButtonPadding: `${rem(8)} ${rem(20)}`,
    generalIconSize: 24,
    mediumIconSize: 35,
    bigIconSize: 40,
    veryBigIconSize: 50,
    smallerTextSize: 14,
    baseTextSize: 16,
    mediumTextSize: 18,
    bigTextSize: 20,
    biggerTextSize: 24,
    largeTextSize: 28,
    veryBigText: 50,
    containerPadding: 15,
    appBarPadding: 88,
    appBarLargeScreenHeight: 64,
    largeDecorationHeight: 3,
    smallDecorationHeight: 2,
    ordersBottomNavigationHeight: 85,
    settingsInputContainerWidth: 500,
    buttonHoverBoxShadow: `0px ${rem(2)} ${rem(4)} ${rem(-1)} rgba(0, 0, 0, 0.2)`,
    buttonHoverFilter: `drop-shadow(0px ${rem(1)} ${rem(10)} rgba(0, 0, 0, 0.12)) drop-shadow(0px ${rem(4)} ${rem(
      5
    )} rgba(0, 0, 0, 0.14))`,
    lowDeviceHeight: 680,
  },
  pauseComponent: {
    button: {
      background: {
        primary: '#272727',
        secondary: 'rgba(0, 0, 0, 0.12)',
        tertiary: '#424242',
      },
      border: {
        primary: '#DDDDDD',
      },
      color: {
        pending: 'rgba(255, 255, 255, 0.8)',
      },
    },
    banner: {
      background: {
        default: '#FFF',
        paused: amber[700],
      },
    },
    timer: {
      color: {
        default: '#272727',
        pending: 'rgba(39, 39, 39, 0.6)',
      },
      background: 'rgba(255, 255, 255, 0.3)',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
        },
        '@keyframes fadeIn': {
          '0%': {
            opacity: 0.3,
          },
          '100%': {
            opacity: 1,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        // Name of the component / style sheet
        root: {
          // Name of the rule
          '&$focused': {
            // increase the specificity for the pseudo class
            color: '#ed1c24 !important',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '&.MuiDialogContent-root': {
            paddingTop: '8px',
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'bigContained' },
          style: (params) => ({
            '&.MuiButton-bigContainedPrimary': {
              backgroundColor: params.theme.palette.primary.main,
              '& .MuiTypography-root': {
                color: params.theme.palette.primary.contrastText,
              },
              '&.Mui-disabled': {
                backgroundColor: alpha(params.theme.palette.primary.main, 0.12),

                '& .MuiTypography-root': {
                  color: alpha(params.theme.palette.primary.main, 0.26),
                },
              },
              '&:hover': {
                background: params.theme.palette.primary.main,
                boxShadow: params.theme.generalStyles.buttonHoverBoxShadow,
                filter: params.theme.generalStyles.buttonHoverFilter,
                '& .MuiTypography-noWrap': {
                  color: params.theme.palette.primary.contrastText,
                },
              },
              '&:active': {
                background: params.theme.pauseComponent.button.background.secondary,
              },
            },
            '& .MuiButton-startIcon': {
              marginRight: '8px',

              '& svg': {
                height: '26px',
                width: '16px',
              },
            },
            '& .MuiButton-endIcon': {
              marginLeft: '8px',

              '& svg': {
                height: '26px',
                width: '16px',
              },
            },
            '&.MuiButton-outlined': {
              '&.Mui-disabled': {
                '& .MuiTypography-root': {
                  color: alpha(params.theme.palette.primary.main, 0.26),
                },
              },
            },

            padding: rem(24),
            borderRadius: rem(16),
            minWidth: rem(90),
            boxShadow: 'none',
            '& .MuiTypography-root': {
              textTransform: 'none',
              fontSize: rem(params.theme.generalStyles.bigTextSize),
              fontStyle: 'normal',
              fontWeight: '700',
              letterSpacing: rem(0.15),
              lineHeight: rem(29),
            },
            '&:hover': {
              background: params.theme.palette.background.default,
              color: params.theme.palette.text.default,
              '& .MuiTypography-root': {
                color: params.theme.palette.text.default,
              },
            },
          }),
        },
        {
          props: { variant: 'bigText' },
          style: (params) => ({
            '&.MuiButton-bigText': {
              '&.Mui-disabled': {
                '& .MuiTypography-root': {
                  color: alpha(params.theme.palette.primary.main, 0.26),
                },
              },
            },
            padding: rem(24),
            borderRadius: '16px',
            minWidth: rem(90),
            boxShadow: 'none',
            '& .MuiTypography-root': {
              color: params.theme.palette.text.default,
              textTransform: 'none',
              fontSize: rem(params.theme.generalStyles.bigTextSize),
              fontStyle: 'normal',
              fontWeight: '700',
              letterSpacing: rem(0.15),
              lineHeight: rem(29),
            },
            '&:hover': {
              background: params.theme.palette.background.default,
              color: params.theme.palette.text.default,
              '& .MuiTypography-root': {
                color: params.theme.palette.text.default,
              },
            },
            '&:active': {
              background: params.theme.pauseComponent.button.background.secondary,
            },
          }),
        },
      ],
    },
  },
});

export const getComponentVariantStyles = (
  componentName: keyof Components<Omit<DefaultTheme, 'components'>>,
  variant: string,
  theme: DefaultTheme
): object => {
  const component =
    theme.components &&
    theme.components['MuiButton']?.variants?.find(
      (component: any) => component && component.props && component.props.variant === variant
    );
  if (component?.style) {
    return (component.style instanceof Function ? component.style({ theme }) : component.style) as object;
  } else {
    return {};
  }
};
