import gql from 'graphql-tag';

export const ORDER_PROCESSED_MUTATION = gql`
  mutation completeOrder($id: Int!) {
    completeOrder(id: $id) {
      id
    }
  }
`;

export const MENU_ITEM_STATE_MUTATION = gql`
  mutation changeState($itemId: Int!, $branchId: Int!, $state: MenuItemStateEnum!) {
    changeItemState(itemId: $itemId, branchId: $branchId, state: $state) {
      id
      state
      isStateReadOnly
    }
  }
`;

export const TEMPORARY_DELAY_MUTATION = gql`
  mutation setTempo($branchId: Int!, $service: ServiceTypeEnum!, $minutes: Int!, $expiration: DateTime!) {
    changeTemporaryDelay(branchId: $branchId, service: $service, minutes: $minutes, expiration: $expiration) {
      base {
        permanentMinutes
        temporaryMinutes
        totalMinutes
        temporaryExpiration
      }
      delivery {
        permanentMinutes
        temporaryMinutes
        totalMinutes
        temporaryExpiration
      }
    }
  }
`;

export const REFUND_ORDER_MUTATION = gql`
  mutation refundOrder($orderId: Int!, $branchId: Int!, $managerCode: String!, $skipDeliveryCancellation: Boolean!) {
    refundOrder(
      orderId: $orderId
      branchId: $branchId
      managerCode: $managerCode
      skipDeliveryCancellation: $skipDeliveryCancellation
    ) {
      id
      status
    }
  }
`;

export const THIRD_PARTY_CANCEL_ORDER_MUTATION = gql`
  mutation cancelThirdPartyOrder($orderId: Int!, $branchId: Int!, $managerCode: String!) {
    cancelThirdPartyOrder(orderId: $orderId, branchId: $branchId, managerCode: $managerCode) {
      id
      status
    }
  }
`;

export const THIRD_PARTY_DELIVERY_PARTNER_STATE_MUTATION = gql`
  mutation updateThirdPartyDeliveryPartnerStatus($partnerId: Int!, $branchId: Int!, $isDisabled: Boolean!) {
    updateThirdPartyDeliveryPartnerStatus(partnerId: $partnerId, branchId: $branchId, isDisabled: $isDisabled) {
      id
    }
  }
`;

export const PAUSE_RESTAURANT = gql`
  mutation pauseRestaurant(
    $branchId: Int!
    $channels: [OrderChannelEnum]!
    $pauseAmount: PauseAmountEnum!
    $synchronizeDelay: Boolean!
  ) {
    pauseRestaurant(
      branchId: $branchId
      channels: $channels
      pauseAmount: $pauseAmount
      synchronizeDelay: $synchronizeDelay
    ) {
      channel
      pausedUntil
    }
  }
`;

export const RESUME_RESTAURANT = gql`
  mutation resumeRestaurant($branchId: Int!, $channels: [OrderChannelEnum]!) {
    resumeRestaurant(branchId: $branchId, channels: $channels) {
      channel
      pausedUntil
    }
  }
`;

export const MARK_ORDER_READY_FOR_PICKUP = gql`
  mutation markOrderAsReadyForPickup($orderId: Int!, $channel: OrderChannelEnum!) {
    markOrderAsReadyForPickup(orderId: $orderId, channel: $channel)
  }
`;
