import { gql } from 'graphql-tag';

export const PAUSE_STATES_SUBSCRIPTION = gql`
  subscription pauseStates($branchId: Int!) {
    pauseStates(branchId: $branchId) {
      channel
      pausedUntil
      status
      statusCreatedAt
    }
  }
`;

export const READY_FOR_PICKUP_RESULT_SUBSCRIPTION = gql`
  subscription readyForPickupResult($orderId: Int!) {
    readyForPickupResult(orderId: $orderId) {
      orderId
      channel
      status
      details
    }
  }
`;
