import { BagType, FeeType } from '../orderDetail/OrderDetailTypes';

export interface OrderDetailPageType {
  title: string;
  paid: string;
  notPaid: string;
  loading: string;
  error: string;
  mutationError: string;
  pickupDateTitle: string;
  readyDateTitle: string;
  address: string;
  phone: string;
  buzzer: string;
  table: string;
  room: string;
  office: string;
  remarks: string;
  allergies: string;
  applicationFees: string;
  deliveryFees: string;
  allergyFees: string;
  promotion: string;
  tip: string;
  grandTotal: string;
  subTotal: string;
  quantity: string;
  back: string;
  process: string;
  processPosIntegrated: string;
  [key: string]: string;
  serviceQuestions: string;
  trackDelivery: string;
  trackDeliveryTitle: string;
  readyForPickup: string;
  readyForPickupConfirmation: string;
  readyForPickupConfirmationYes: string;
  readyForPickupConfirmationNo: string;
  readyForPickupError: string;
  readyForPickupInfoText: string;
  [BagType.NON_REUSABLE_BAG]: string;
  [BagType.REUSABLE_BAG]: string;
  [FeeType.BAG_FEE]: string;
}

export const enCa: OrderDetailPageType = {
  title: 'order detail',
  paid: 'paid',
  notPaid: 'not paid',
  loading: 'loading order detail...',
  error: 'an error has occured',
  mutationError: "the order couldn't been processed",
  pickupDateTitle: 'pickup date',
  readyDateTitle: 'delivery date',
  location: 'location',
  address: 'address',
  phone: 'phone #',
  buzzer: 'buzzer #',
  table: 'table #',
  room: 'room #',
  office: 'office location',
  remarks: 'remarks',
  allergies: 'allergies',
  applicationFees: 'application fees',
  deliveryFees: 'delivery fees',
  allergyFees: 'allergy fees',
  promotion: 'Promotion applied',
  tip: 'tip',
  grandTotal: 'grand total',
  subTotal: 'sub total',
  quantity: 'Quantity',
  back: 'back',
  process: 'process',
  processPosIntegrated: '(Managed by your POS)',
  refund: 'refund',
  refunded: 'Refunded',
  managerCode: 'Manager Code',
  refundOrder: 'Refund of the order #',
  managerCodeError: 'Manager code invalid',
  refundSuccessful: 'Order has been successfully refunded! A confirmation email has been sent to the customer.',
  cancelSuccessful: 'Order has been successfully cancelled! A confirmation email has been sent to the customer.',
  serviceQuestions: 'Service questions',
  cancel: 'Cancel',
  cancellation: 'Cancellation',
  cancellationOrder: 'Cancellation of the order #',
  cancelThirdPartyOrderSuccessful: 'Order has been successfully cancelled! A notification has been sent to ',
  confirm: 'Confirm',
  unableCancelMessage: 'THIRD-PARTY-INTEGRATION orders cannot be cancelled from UEAT.',
  skipCompletedDeliveryQuestion: 'Delivery already completed. Do you still want to refund the order?',
  skipDeliveryQuestion: 'Delivery cannot be cancelled. Are you sure you want to refund the order?',
  trackDelivery: 'View tracking',
  trackDeliveryTitle: 'Order #{{orderNumber}} tracking',
  readyForPickup: 'Ready to be picked up',
  readyForPickupConfirmation: 'Are you sure the order is ready to be picked up?',
  readyForPickupConfirmationYes: 'Yes, the order is ready',
  readyForPickupConfirmationNo: 'Cancel',
  readyForPickupError: 'An error occurred. Please try again later.',
  readyForPickupInfoText: 'This order is ready to be picked up:',
  [BagType.NON_REUSABLE_BAG]: 'Non reusable',
  [BagType.REUSABLE_BAG]: 'Reusable',
  [FeeType.BAG_FEE]: 'Bag fee',
};

export const frCa: OrderDetailPageType = {
  title: 'détails de la commande',
  paid: 'payée',
  notPaid: 'à payer',
  loading: 'chargement des détails de la commande...',
  error: 'erreur de chargement',
  mutationError: "la commande n'a pas pu être traitée",
  pickupDateTitle: 'date de ramassage',
  readyDateTitle: 'date de livraison',
  location: 'succursale',
  address: 'adresse',
  phone: '# de téléphone',
  buzzer: '# de sonnette',
  table: '# de table',
  room: '# de chambre',
  office: 'emplacement du bureau',
  remarks: 'remarques',
  allergies: 'allergies',
  applicationFees: "frais d'application",
  deliveryFees: 'frais de livraison',
  allergyFees: "frais d'allergies",
  promotion: 'Promotion appliquée',
  tip: 'pourboire',
  grandTotal: 'grand total',
  subTotal: 'sous-total',
  quantity: 'Quantité',
  back: 'retour',
  process: 'traiter',
  processPosIntegrated: '(Géré par votre POS)',
  refund: 'rembourser',
  refunded: 'Remboursée',
  managerCode: 'Code gérant',
  refundOrder: 'Remboursement de la commande #',
  managerCodeError: 'Code gérant invalide',
  refundSuccessful: 'La commande a été remboursée avec succès! Un courriel de confirmation a été envoyé au client.',
  cancelSuccessful: 'La commande a été annulée avec succès! Un courriel de confirmation a été envoyé au client.',
  serviceQuestions: 'Questions de service',
  cancel: 'Annuler',
  cancellation: 'Annulation',
  cancellationOrder: 'Annulation de la commande #',
  cancelThirdPartyOrderSuccessful: 'La commande a été annulée avec succès! Une notification a été envoyé au ',
  confirm: 'Confirmer',
  unableCancelMessage: 'Les commandes THIRD-PARTY-INTEGRATION ne peuvent pas être annulées via UEAT.',
  skipCompletedDeliveryQuestion: 'La commande a déjà été livrée. Voulez-vous quand même rembourser la commande ?',
  skipDeliveryQuestion: "La livraison n'a pas pu être annulée. Voulez-vous quand même rembourser la commande ?",
  trackDelivery: 'Voir le suivi',
  trackDeliveryTitle: 'Suivi de la commande #{{orderNumber}}',
  readyForPickup: 'Prêt à être récupéré',
  readyForPickupConfirmation: 'Êtes-vous certain que la commande est prête à être récupérée ?',
  readyForPickupConfirmationYes: 'Oui, la commande est prête',
  readyForPickupConfirmationNo: 'Annuler',
  readyForPickupError: 'Une erreur est survenue. Veuillez réessayer plus tard.',
  readyForPickupInfoText: 'Cette commande est prête à être récupérée :',
  [BagType.NON_REUSABLE_BAG]: 'Non réutilisable',
  [BagType.REUSABLE_BAG]: 'Réutilisable',
  [FeeType.BAG_FEE]: 'Frais de sac',
};
