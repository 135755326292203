import { useMutation, useSubscription } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MARK_ORDER_READY_FOR_PICKUP } from '../mutations/mutations';
import { OrderChannelEnum } from '../pauseChannel/types/PauseTypes';
import { parseGraphQLErrorMessage } from '../app/ErrorUtils';
import { enqueueSnackbar } from 'notistack';
import { useTraductions } from '../app/TraductionsContext';
import { snackbarCloseAction } from '../app/SnackbarActions';
import { Button, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useState } from 'react';
import { rem } from '../app/AppUtilities';
import { READY_FOR_PICKUP_RESULT_SUBSCRIPTION } from '../subscriptions/subscriptions';
import ReadyForPickupConfirmationModal from './ReadyForPickupConfirmationModal';
import { Order } from './OrderDetailTypes';
import { ORDER_DETAIL_QUERY } from '../queries/queries';
import { useSettings } from '../app/SettingsContext';

interface Props extends RouteComponentProps {
  order: Order;
}

interface MarkAsReadyForPickupVariables {
  orderId: number;
  channel: OrderChannelEnum;
}

interface ReadyForPickupSubscriptionVariables {
  orderId: number;
}

interface ReadyForPickupResult {
  orderId: number;
  channel: OrderChannelEnum;
  status: ReadyForPickupStatus;
  details: string;
}

enum ReadyForPickupStatus {
  SUCCESS = 'SUCCESS',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  ALREADY_NOTIFIED = 'ALREADY_NOTIFIED',
  MARKETPLACE_FAILURE = 'MARKETPLACE_FAILURE',
}

interface ReadyForPickupResultSubscriptionData {
  readyForPickupResult: ReadyForPickupResult;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    readyForPickupButton: {
      flexBasis: `calc(57.5% - ${rem(12)})`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: rem(theme.generalStyles.biggerTextSize),
    },
  })
);

function ReadyForPickupButton({ order }: Props) {
  const { id: orderId, channel, readyForPickupNotifiedAt } = order;
  const settings = useSettings();
  const traductions = useTraductions();
  const classes = useStyles();
  const [waitingForResult, setWaitingForResult] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const [markOrderAsReadyForPickup, { loading: requesting }] = useMutation<boolean, MarkAsReadyForPickupVariables>(
    MARK_ORDER_READY_FOR_PICKUP,
    {
      onError: ({ graphQLErrors, networkError }) => {
        // Shouldn't happen once everything is configured properly, but we need to handle it just in case
        const errorMessage = networkError ? parseGraphQLErrorMessage(networkError.message) : graphQLErrors[0].message;
        enqueueSnackbar({
          message: errorMessage || traductions.main.genericError,
          variant: 'error',
          action: snackbarCloseAction,
        });
      },
    }
  );

  useSubscription<ReadyForPickupResultSubscriptionData, ReadyForPickupSubscriptionVariables>(
    READY_FOR_PICKUP_RESULT_SUBSCRIPTION,
    {
      variables: {
        orderId,
      },
      onData({ data, client }) {
        const result = data.data?.readyForPickupResult;
        if (isSuccess(result)) {
          client.writeQuery<{ order: Order }>({
            query: ORDER_DETAIL_QUERY,
            variables: {
              id: orderId,
              branchId: settings.currentBranchId,
              restaurantId: settings.currentRestaurantId,
            },
            data: {
              order: {
                ...order,
                readyForPickupNotifiedAt: new Date().toISOString(),
              },
            },
          });
        } else {
          console.error(`Error marking order ${orderId} as ready for pickup: ${result?.status}: ${result?.details}`);
          enqueueSnackbar({
            message: traductions.orderDetailPage.readyForPickupError,
            variant: 'error',
            action: snackbarCloseAction,
          });
        }
      },
    }
  );

  const handleClick = () => {
    setConfirmationDialogOpen(true);
  };

  const handleConfirm = async () => {
    setConfirmationDialogOpen(false);
    const result = await markOrderAsReadyForPickup({
      variables: {
        orderId,
        channel,
      },
    });

    setWaitingForResult(!!result.data);
  };

  const handleCancel = () => {
    setConfirmationDialogOpen(false);
  };

  return (
    <>
      <ReadyForPickupConfirmationModal
        isOpen={confirmationDialogOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
      <Button
        disabled={!!readyForPickupNotifiedAt || requesting || waitingForResult || confirmationDialogOpen}
        classes={{
          root: classes.readyForPickupButton,
        }}
        color="primary"
        variant="contained"
        data-testid="order-detail-ready-for-pickup-button"
        onClick={handleClick}
      >
        {traductions.orderDetailPage.readyForPickup}
      </Button>
    </>
  );
}

function isSuccess(result: ReadyForPickupResult | undefined) {
  // ALREADY_NOTIFIED is treated as a success, because in the end we did what the user wanted
  return result && [ReadyForPickupStatus.SUCCESS, ReadyForPickupStatus.ALREADY_NOTIFIED].includes(result.status);
}

export default withRouter(ReadyForPickupButton);
